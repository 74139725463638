body{
    background: #00001B!important;
}
.conteudo{
    min-height: 100%;
    position: relative;
    width: 100%;
    background: #00001B;
}

/*Carrossel*/
.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
    display: none;
}


/*Logo*/
.logo img{width: 220px;position: relative; margin: 10px auto;display: block;}


header .logo h1{
    width: 240px;
    height: 60px;
    display: block;
    text-indent: -9999px;
    background: url(images/logo-103.png)center center no-repeat;
    background-size: 80%;
    margin: 15px auto;
}


/*MENU*/
.navbar{margin-top: 10px;margin-bottom:0px;border:0px; width: 1170px;margin: 0 auto;}

.navbar:before{
    content: url(images/esquerda.png);
    position: absolute;
    top: 0;
    left: -22px;
}

.navbar:after{
    content: url(images/direita.png);
    position: absolute;
    top: 0;
    right: -22px;}

@media (max-width: 1150px) {
    .navbar{
        width: 100%;
    }
    .navbar:before{
        display: none;
    }
    .navbar:after{
        display: none;
    }

}


@media (max-width: 1170px) { }


@media (max-width: 1170px) { }





.mega-nav {
    background: #441A4C;
    border-radius: 0;
    border-bottom: 1px solid rgb(121, 50, 135);
}

.mega-nav.navbar-default .navbar-nav > li > a {
    color:#fff;
    transition: all 200ms ease-out;
    -webkit-transition: all 200ms ease-out;
    -ms-transition: all 200ms ease-out;
    font-weight: bold;
    text-transform: uppercase;
}

.mega-nav.navbar-default .navbar-nav > li > a:hover {
    background:rgba(247, 127, 3, 0.1);
}
.mega-nav.navbar-default .navbar-nav > li.dropdown > a:hover,
.mega-nav.navbar-default .navbar-nav > li.dropdown > a:focus,
.mega-nav.navbar-default .navbar-nav > li.dropdown > a:active{
    background:rgba(247, 127, 3, 0.1);
    color:#fff;
}
#MainMenu {
    padding-left:0;
}

.mega-nav.navbar-default .navbar-nav > li.menu-list .list-category a {
    color:#333;
}

.navbar-default .navbar-toggle{background: #fff}

/* on activation */


#toggle.on + #menu {
    height: auto;
    margin-top: 0px;
    visibility: visible;
    overflow-y: auto;
    width: auto;
    font-size: 0.9em;
    opacity: 1;
    background: #441a4c;
    overflow-y: hidden;
    z-index: 155
}

#toggle.on + #menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
    border-radius: 1px;
    background-color: #000;
}
#toggle.on + #menu::-webkit-scrollbar {
    background-color: #000;
}

#toggle.on + #menu::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,.3);
    background-color: #555;
}


/*TIM Style*/
#checkTim {
    width: 20px;
    height: 20px;
    float: left;
    margin-top: 33px;
    margin-left: 62px;
}

/*Media Queries*/
@media screen and (max-width: 764px) {
    #checkTim{
        margin-left: 12px;
    }
}
@media screen and (max-width: 500px) {
    #checkTim {
        margin-left: 2px;
    }
}



/* menu appearance*/
#menu {
    position: absolute;
    color: #999;
    width: 100%;
    padding: 10px;
    margin: auto;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
    background: white;
    box-shadow: 0 1px 8px rgba(0,0,0,0.05);
    opacity: 0;
    height: 0px;
    visibility: hidden;
    font-size: 0px;
}


#menu ul {width: 1120px}

#menu ul, li,a {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    display: inline-block;
    white-space: normal;
    text-align: left;
}

#menu ul li{
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    display: inline-block;
    white-space: normal;
}

#menu ul a{
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    display: inline-block;
    white-space: normal;
}




#menu li a {
    padding: 5px;
    color: #888;
    text-decoration: none;
    transition: all .2s;
    overflow: auto;
    color:#fff;
    font-size: 1em;
    text-transform: uppercase;
    font-weight:bold;
    padding: 8px;
}
#menu li a:hover,
#menu li a:focus {
    background:#db2fc4;
    color: #fff;
}

#menu .navbar-form{
    padding: 1px 0px;
    margin-top: 7px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    -webkit-box-shadow: 0px!important;
    box-shadow: none;

}

#MainMenu > ul{
    margin: 1px -15px!important;
    position: absolute!important
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form{border: 0px;}

#banner{
    content: "";
    background: url("images/banner.png")no-repeat center center;
    display: block;
    width: 1170px;
    height: 80px;
    margin:10px auto;
    cursor: pointer;
    position: relative!important;
}

.quentes .container h2 {
    font-size: 1.2em;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    background: #441a4c;
    padding: 5px;
    text-align: center;
}

.quentes .container{
    background: #00001B;
}

.nav>li>a:focus, .nav>li>a:hover {
    text-decoration: none;
    background-color: #eee;
}


#MainMenu > ul{
    display: flex;
    flex-wrap: wrap;
}

/*Helpers*/
.clean{clear: both;}
.white{color: #fff}
.titulos{font-size:1.2em;color: #fff; text-transform: uppercase;margin-top: 40px;font-weight: bold}

.titulosIcon{font-size:1.2em;color: #fff; text-transform: uppercase;
    font-weight: bold;  display: inline-block;position: relative;top:15px;margin-bottom:25px }

.tituloVideo{font-size:1.2em;color: #fff; text-transform: uppercase;font-weight: bold;display: inline;position: relative;top: 15px;}

.hr{border-top: 1px solid #383636;}

/*Videos*/

.descVideo{
    color: #fff;
    font-weight:bold;
    font-size: 1.1em;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 265px;
    margin-bottom: 8px }




.iconEye{margin-top:-5px; position: absolute; }

.iconEyeVd{margin-top:-5px;float: right}


.col-md-3{margin-bottom: 25px}
.col-md-4{margin-bottom: 25px}

.videos .container{
    background: #00001B;
}

.videos h1{font-size:1.2em;color: #fff; text-transform: uppercase;margin-top: 40px;font-weight: bold}

.videos h1,span{vertical-align: middle;display: inline-block}


.videos h2{font-size:1.2em;color: #fff; text-transform: uppercase;margin-top: 40px;font-weight: bold}

.videos h2,span{vertical-align: middle;display: inline-block}

.play i{
    background: #a447b6;
    position: relative;
    top: 13px;
    left: 3px;
    color: #fff;
    font-size: 1em;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    line-height: 20px;
    vertical-align: middle;
    margin-right: 10px
}

.fa-play:before{
    position: relative;
    left: 7px;
    top:2px
}
.tagsIcon{
    margin-bottom: 20px;
}

 .tagsIcon i{
     background: #a447b6;
    position: relative;
    top: 13px;
    left: 3px;
    color: #fff;
    font-size: 1em;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    line-height: 20px;
    vertical-align: middle;
    margin-right: 10px
}

.fa-tags:before{
    position: relative;
    left: 5px;
    top: 2px;
    font-size: 1.1em;
}



.imgZoom figure img{
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.imgZoom figure:hover img{
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    box-shadow:0 1px 3px rgba(0,0,0,.25);
}

.img-responsive{width: 100%}


/*PageVídeos*/
.videobox{margin-top: 25px;}

.lights{
    margin-top: 15px;
    width: 60px;
    height: 60px;
    border: 1px solid gray;
    text-align: center;
    border-radius: 5px;
}

.light{
    cursor: pointer
}

.lights i{
    position: relative;
    top: 3px;
}

.lights i p{
    text-align: center;
    font-size: 12px;
}


#apaga {
    background:#000;
    opacity:0.9;
    filter:alpha(opacity=90);
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:100;
}

.video{z-index: 102;position: relative}

.tagvideo{
    margin-top: -0px!important;
}

.videosRelacionados{
    margin-bottom: 45px;
}

.player-img img{
    margin: 0 auto;
}


.player-img-icon{
    display: block;
    position: absolute;
    left: 47%;
    top: 38%;
    margin-top: 0;
    margin-left: 0;
    width: 64px;
    height: 64px;
    background-image: url(images/play.png);
    z-index: 100;
    cursor: pointer;
    max-width: 100%;
}




/*Nuvem de Tags*/

.tagcloud{
    margin-top: 70px;
    margin-bottom: 50px;
}

.tagcloud ul {
    margin: 4px 2px;
    padding: 0;
    list-style: none;
}
.tagcloud ul li {
    display: inline-block;
    margin: 0 .3em .3em 0;
    padding: 0;
}
.tagcloud ul li a {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    padding: 0 1em;
    background-color: #441a4c;;
    border: 1px solid #000;
    border-radius: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #e2e2e2;
    font-size: 13px;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: .2s;
    transition: .2s;
}
.tagcloud ul li a:hover {
    background-color: #A447B6;
    border: 1px solid #A447B6;
    color: #fff;
}


/*Loader*/
#loading {
    width: 65px;
    height: 65px;
    border: 2px solid #fff;
    border-top-color: #f900ff;
    border-radius: 100%;
    top: 155px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    animation: round 1s linear infinite;
    position: relative;
}

@keyframes round {
    from{transform: rotate(0deg)}
    to{transform: rotate(360deg)}
}
#overlay_box{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background: #00001B;
}
#overlay {
    height:100%;
    width:100%;
    background:#00001B;
    opacity:1;
    left:0;
    top:0;
    z-index:9999;
}


#overlay > div > div{
    background: #00001B;
}



/*FOOTER*/

footer .container{
    background: #00001B;
}

.footer_text h3{
    text-align: center;
    color: #fff;
    font-size: 1.2em;
}

.footer_text .links{
    text-align: center;
}

.footer_text .links a{
    text-align: center;
    color: #bdb9b9;
    text-decoration: none;
    margin-right: 15px;
    display: inline-block;
}

.footer_text .copy{
    text-align: center;
    color:#828282;
    font-size: 0.8em
}

.description p{
    color: #fff;
    font-size: 1.2em;
}


/*Overlay IMG*/
.overlay_img{
    background: url(images/modal-voyer.jpg) center top no-repeat!important;
    background-size: cover;
    height:250px;
    width: 600px;
    display: block;
    margin: 25px auto;
    max-width: 100%;
}

.overlay_img p{
    color: #fff;
    font-weight: bold;
    position: relative;
    top: 15px;
    left: 15px;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.7);
    text-align: center;
    line-height: 1.5em
}

.buttons{
    display: block;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-right: 15px
}

.buttons img{
    margin-right: 15px;
}


/*PAGE Categorias */
.titleCat{
    color: #fff;
    font-size: 1.8em;
    text-align: center;
    font-weight: bold;
}

.paragrafoCat{
    margin-top: 25px;
    color: #fff;
    font-size: 1.1em
}

.pagination{
    margin: 0 auto
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
    background-color: #a447b6;
    border-color: #a447b6;
    color: #fff
}

.pagination>li>a, .pagination>li>span{
    color: #000
}


/*Page Termos*/
.termos h1{
    color: #fff;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center
}

.termos h2{
    color: #fff;
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 15px
}

.termos p{
    color: #eceaea;
    font-size: 1em;
    line-height: 18px
}

#infoAuth{
    margin: 0 auto;width: 206px;text-align: center;color:#fff
}



/*PAGE Informações */
.informacoes h1{
    color: #fff;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center
}

.informacoes h2{
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
    text-align: left
}

.informacoes p{
    color: #eceaea;
    font-size: 1.1em;
    line-height: 23px
}

.informacoes a{
    font-weight: bold;
    color: rgb(237, 156, 255);
    margin-bottom: 15px;
    margin-top: 10px;
}

/*Login User*/
.login-user{
     background: url(images/login-voyer.jpg)center center no-repeat ;
     background-size: cover;
     width: 100%;
     height: 300px;
     margin: 0 auto;
 }
.login-user:before{
    background-color: rgba(0, 0, 0, 0.42);
    content: '';
    display: block;
    height: 300px;
    position: absolute;
    width: 1160px;
}

.box-login{
    display: block;
    width: 450px;
    margin: 0 auto;
    position: relative;
    top: 85px;
    background: rgba(0, 0, 0, 0.58);
    padding: 20px;
    border-radius: 15px;
}

.box-login p{
    color: #fff;
    font-size: 1.3em;
    text-shadow: 2px 1px 1px #000;
    margin:10px auto;
}


.box-login input{
    height: 40px;
    padding: 5px;
    font-size: 1em;
    color: #000;
    font-weight: bold;
    min-width: 44px;
    max-width: 45px;
}

#telefone{
    min-width: 179px;
    max-width: 180px;
}

.box-pin div{
    padding:15px;
}

.box-pin p{
    font-size: 1.1em;
}

.formsLogin{
    background: url(images/login-voyer.jpg)center center no-repeat ;
    background-size: cover;
    width: 100%;
    height: 500px;
    margin: 0 auto;
    position: relative;
}


.box-pin{
    position: relative;
    display: block;
    width: 350px;
    margin: 0 auto;
    top: 50px;
    background: rgba(0, 0, 0, 0.67);
    color: #fff;
    border-radius: 15px;
    height: 400px;
}



.btn-warning{
    width: 240px;
    margin-top: 10px;
    background: #441A4C;
    border: 1px solid #441A4C;
    color: #fff!important;
}
.btn-warning:hover{
background:#441A4C;
    border: 1px solid #441A4C;
}

.btn-warning.focus, .btn-warning:focus{
background:#441A4C!important;
border: 1px solid #441A4C;

}


    /*Input Code*/
.inputCode{
    width: 250px;
    height: 105px;
    background: #262626;
    margin: 3px auto;
    border-radius: 4px;
    color: #000;
}

.inputCode label{
    color: #fff;
    text-transform: uppercase;
}

.inputCode #code{
    text-align: center;
    border: 2px solid orange;
    border-radius: 5px;

}

.inputCode #code:hover{
    border: 2px solid orange;
}

.inputCode #code:focus{
    border: 0px solid #fff!important;
}

#codeButton{
    width: 240px!important;
    height: 40px;
    margin-right: -25px;
}


.infoAssinatura{
    width: 180px;
    font-size: 0.8em!important;
    margin: 15px auto;
    text-align: left
}

#code .check{
    position: relative;
    top: 76px;
    left: -225px;
    -ms-transform: scale(2);
    -moz-transform: scale(2);
    -webkit-transform: scale(2);
    -o-transform: scale(2);
}

.join{
    margin-bottom: 25px
}

.join .check{
    position: relative;
    top: 46px;
    left: -218px;
    width: 20px;
}
checkboxStyle{

}



.join .sairServico{
    font-size: 0.7em;
    color: #e8e4e4;
    background: black;
    border-radius: 3px;
    margin-bottom: 50px
}


.negMargen{
    margin-top: -15px;
}



.navbar-form{
    -webkit-box-shadow: inset 0 0px 0 rgba(255,255,255,.1), 0 0px 0 rgba(255,255,255,.1);
    box-shadow: inset 0 0px 0 rgba(255,255,255,.1), 0 0px 0 rgba(255,255,255,.1);
}


.login-user{
    margin-bottom: 50px;
}

/*Page Pesquisa*/

.searchNot h1{
    font-size: 1.7em;
    color:#fff
}

.searchNot p{
    color: #fff;
    font-size: 1.3em
}

/*404*/
.erro-q h1{
    color: #fff
}

.erro-q h2{
    color:#fca501;
}

.erro-q p{
    color: #fff;
    font-size: 1.3em
}




body > div.sweet-alert.showSweetAlert.visible > div.sa-button-container > div > button{
    background-color: #b254c5!important
}

.sweet-alert button{
    background-color: #b254c5!important
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.navbar-form{
    margin-bottom: -23px
}
.iconEyeHot{position: relative; top:-10px}


/*Video*/
.video-js .vjs-big-play-button{
    top: 40%;
    left: 45%;
    background-color: rgba(253, 123, 0, 0.49);
}

.video-js{
    margin:0 auto;
}


section.quentes > div > div > div:nth-child(4){
    display: none;
}


section.videos > div > div:nth-child(3) > div:nth-child(9){
display: none;
}
#overlay > div > section.videos > div > div:nth-child(6) > div:nth-child(9) {
    display: none;
}


/*MEDIAS*/
@media (max-width: 1201px) {
    #menu ul {
        width: 720px;}

    #banner {
        width: 980px;
        height: 80px;
        height: 68px;
        background-position: 10px;
    }

}


@media (max-width: 992px) {
    section.videos > div > div:nth-child(3) > div:nth-child(9) {
        display: block;
    }

    #overlay > div > section.videos > div > div:nth-child(6) > div:nth-child(9) {
        display: block;
    }

    #banner {
        width: 660px;
        height: 80px;
        height: 68px;
        background-position: -40px;
    }

}

@media (max-width: 769px) {
    #MainMenu > ul{position: absolute}

    #menu ul {
        width: 720px;}
}


@media (max-width: 768px){
    #MainMenu > ul{position: relative!important;display: table; margin: 0 auto;display: inline-flex}

    #toggle.on + #menu{
        height: auto;
        display: block;
        margin: 0 auto;
        font-size:0.8em;
    }

    #menu ul{
        display: block;
        width: 240px;
        text-align: left

    }
    .hotVideos{
        margin-bottom: 15px
    }

    .iconEyeVd{margin-top:-55px;float: right}

    .iconEyeHot{position: relative; top:-10px;font-size: 0.9em}

    .iconEye{font-size: 0.9em}

    .box-login{
        width: 345px;
        max-width: 100%;
        top:80px;
        height:295px;
    }

    .login-user{
        background-size: 100%;
        height: 150px;
    }

    .overlay_img{
        height: 150px;

    }

    .overlay_img p {
        left: 0;
    }

    .negMargen{
        margin-top: -25px;
    }

    .bottomMarge{
        margin-bottom: 185px;
    }

    .descVideo{
        font-size: 0.8em;
    }

    .tagcloud ul li a{ font-size: 0.8em}

    .player-img-icon{
        left: 42%;
        top: 30%;
    }

    .embed-responsive-16by9{
        padding-bottom: 400px;
    }

    .video-js .vjs-big-play-button{
        top:35%;
        left:40%;
    }



}


@media (max-width: 767px) {
    .col-custom {
        position: relative;
        float: left;
        width: 33%;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 auto;
        margin-bottom: 15px;
    }


}
@media (max-width: 700px) {
    .col-custom {
        position: relative;
        float: left;
        width: 50%;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 auto;
        margin-bottom: 15px;
    }

    section.quentes > div > div > div:nth-child(4) {
        display: block;
    }

    section.videos > div > div:nth-child(3) > div:nth-child(9) {
        display: none;
    }

    #overlay > div > section.videos > div > div:nth-child(6) > div:nth-child(9) {
        display: none;
    }

    #banner {
        width: 500px;
        height: 58px;
        background-position: -30px;
        background-size: 150%;
    }

}

@media (max-width: 500px) {
    .col-custom {
        position: relative;
        float: left;
        width: 100%;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 auto;
        margin-bottom: 15px;
    }

    #banner{
        width: 300px;
        height: 58px;
        margin: 10px auto;
        cursor: pointer;
        position: relative!important;
        background-position: -20px;
        background-size: 250%;
    }

    section.quentes > div > div > div:nth-child(4){
        display: none;
    }
}





/*# sourceMappingURL=style-105.css.map */
